<template>
  <div class="c-exercise" :id="getID()" >
    <template v-if="exercise">
      <div class="c-exercise__status c-exercise__participant-name">
        <div class="c-exercise__status-label name">{{$t(getStatus())}}</div>
        <div class="c-session-block__meta-info">
          {{ $t('exercise.type.' + exercise.exerciseTypeId) }}
        </div>
        <div class="c-exercise__status-box">

        </div>
      </div>

      <div class="c-exercise__participant c-exercise__stripped-block">
        <div class="c-exercise__participant-info">
          <component
            :is="'participant-'+ participation.participantType"
            :participation="participation"
            class="c-exercise__participant-name"
            />

          <div class="c-exercise__cat">
            <span class="c-exercise__cat-label">{{$t('cat')}}</span>
            {{getCategory().name}}
            <span v-if="isTeamEvent && !noedit">
              <br/>{{exercise.noTeam ? $t('exercise.noTeam') : $t('exercise.team')}}
              (<a href="#" v-on:click.prevent="toggleTeam()">{{$t('panel.change')}}</a>)
            </span>
            <span v-if="useJoker && !noedit">
              <br/>{{exercise.joker ? $t('exercise.joker') : $t('exercise.nojoker')}}
              (<a href="#" v-on:click.prevent="toggleJoker()">{{$t('panel.change')}}</a>)
            </span>
          </div>
        </div>
      </div>

      <div class="c-exercise__stripped-block c-exercise__results">
        <exerciseResult v-for="pass in getPasses()" :key="pass" :exercise="exercise" :pass="pass" />
      </div>
    </template>

    <template v-else >
      <div>Missing exercise</div>
    </template>
  </div>
</template>

<script>
  import find from 'lodash/find';
  import range from 'lodash/range';

  import blockLib from "@/lib/block";
  import exerciseLib from "@/lib/exercise";
  import participantLib from 'client/lib/participant';
  import rounds from 'client/lib/rounds'

  export default {
    name: "blockExercise",
    components: {
      'participant-clubMember': () => import('client/components/models/participantClubMember.vue'),
      'participant-group': () => import('client/components/models/participantGroup.vue'),
      'exerciseResult': () => import('client/components/models/exerciseResult.vue'),
    },
    props: ['blockParticipation', 'exerciseTypeId', 'pass', 'noedit', 'panelId', 'label'],
    data: function() {
      return {
        passes: 1,
        status: 'present',
        isTeamEvent: false,
        useJoker: false,
      }
    },
    computed: {
      exercise: function() {
        const exerciseTypeId =
          blockLib.getBlockParticipationExerciseType(this.blockParticipation, this.exerciseTypeId, this.panel)

        return exerciseLib.getExercise(
          this.blockParticipation.participationId, this.panel.roundIndex, exerciseTypeId)
      },
      panel: function() {
        return find(this.$store.state.panels.items, item => { return item.id === this.panelId });
      },
      categories: function() {
        return this.$store.state.categories.items;
      },
      participation: function() {
        return participantLib.getParticipation(this.blockParticipation.participationId);
      },
      clubs: function() {
        return this.$store.state.clubs.items;
      },
      members: function() {
        return this.$store.state.members.items;
      },
      settings: function() {
        return find(this.$store.state.panels.configurations, item => {
          return item.panelId === this.panelId;
        });
      },
    },
    created: function() {
      this.setStatus();
      if (this.exercise && this.exercise.config && this.exercise.config.passes) {
        this.passes = this.exercise.config.passes;
      }
      if (this.exercise && this.exercise.config && this.exercise.config.useJoker) {
        this.useJoker = this.exercise.config.useJoker;
      }
    },
    watch: {
      participation: function() {
        this.setStatus();
      }
    },
    methods: {
      setStatus: function() {
        const partRound = find(this.participation.rounds, item => item.roundIndex === this.panel.roundIndex)
        this.status = partRound.status

        // evaluate if this is a team competition:
        const round = rounds.getRound(this.exercise.roundIndex)

        const roundRanking = find(round.rankings, i => i.rankingType === 'TEAM-AA')

        this.isTeamEvent = (roundRanking && roundRanking.enabled);
      },
      getID: function() {
        let id = 'ex-' + this.exercise.id;
        if (this.settings.splitPasses) {
          id += '-' + this.pass;
        }
        return id;
      },
      getCategory: function() {
        return find(this.categories, i => i.id === this.participation.categoryId)
      },
      getStatus: function() {

        if (this.status !== 'present') {
          return 'participation.status.' + this.status;
        }
        //const exercise = this.getExercise();

        if (this.exercise) {
          return 'exercise.status.' + this.exercise.status;
        }
        return 'exercise.status.missing';
      },
      getPasses: function() {
        if (parseInt(this.pass) === 0) {
          return range(this.passes);
        }
        else {

          return [this.pass-1];
        }
      },
      getExerciseLabel: function() {
        return participantLib.getExerciseLabel(this.participation, this.exercise.roundIndex, this.exercise.exerciseTypeId)
      },
      openScore: function() {
        this.$emit('open', {exerciseId: this.exercise.id, pass: this.pass===0 ? 1 : this.pass});
      },

      setParticipationStatus: function(newStatus) {
        this.$store.dispatch("participation.setStatus",
          { participationId: this.participation.id, roundIndex: this.panel.roundIndex, status: newStatus, force: false}).then(
          () => {},
          err => {
            console.log(err);
            if (err.status === 409 && err.body.error.name === 'ERR_EXERCISES') {
              this.$modal.show({
                title: this.$t('participation.hasExercises'),
                message: this.$t('participation.hasExercises.text'),
                onConfirm: () => {
                  this.$store.dispatch("participation.setStatus",
                    { participationId: this.participation.id, roundIndex: this.panel.roundIndex, status: newStatus, force: true});
                }
              });
            }
          },
        );
      },
      setExerciseStatus: function(newStatus) {
        this.$store.dispatch("exercise.setStatus", { exerciseId: this.exercise.id, status: newStatus});
      },

      clearExercise: function() {
        this.$modal.show({
          title: this.$t('panel.clearScores'),
          message: this.$t('panel.clearAlert.text'),
          onConfirm: () => {
            this.$store.dispatch('exercise.clearValues', {exerciseId: this.exercise.id, status: 'scheduled', pass: 0});
          }
        });
      },
      toggleTeam: function() {
        this.$store.dispatch("exercise.setNoTeam", {exerciseId: this.exercise.id, noTeam: ! this.exercise.noTeam});
      },
      toggleJoker: function() {
        this.$store.dispatch("exercise.setJoker", {exerciseId: this.exercise.id, joker: ! this.exercise.joker});
      },
    }
  };
</script>

<style scoped>
.c-exercise {
  display: flex;
  flex-direction: row;
}

.c-exercise .c-exercise__stripped-block {
  padding-top: 10px;
  padding-bottom: 10px;
}

.c-exercise__status {
  width: 100px;
  flex-grow: 0;
  margin: 0 0 0 0;
  padding-left: 8px;
  padding-bottom: 10px;
  background: inherit;
  text-align: left;
}

.c-exercise:nth-child(odd) .c-exercise__status {
  background: #F3F3F3;
}

.c-exercise__status-label {
  display: inline;
  padding-left: 0;
}

.c-exercise__participant {
  flex-grow: 1;
}

.c-exercise__participant-name {
  font-size: 14px;
  line-height: 1.42857;
}

.c-exercise__results {
  flex-grow: 0.1;
  justify-content: flex-end;
  padding-right: 10px;
}

.c-exercise__results .c-exercise__result {
  font-size: 11px;
  min-width: 72px;
}
</style>
