<template>
  <div class="">
    <h4 class="c-title c-title--page-section u-padding-left-tiny">
      {{panel.name}} <span class="" v-if="panel.exerciseTypeId">{{$t('exercise.type.'+ panel.exerciseTypeId)}}</span>
    </h4>

    <div v-for="pass of order" :key="pass.pass">
      <exercise v-for="bp of pass.participations" :key="'ex-' + pass.exerciseTypeId + '|' + bp.id" :noedit="true"
                :blockParticipation="bp" :exerciseTypeId="pass.exerciseTypeId" :panelId="panel.id" pass="0" />
    </div>
  </div>
</template>

<script>
import find from 'lodash/find'

import blockLib from '@/lib/block'

export default {
  name: 'sessionPanel',
  props: ['panel'],
  components: {
    "exercise": require('./blockExercise.vue').default,
  },
  data: function() {
    return {
      rotation: null,
      order: null,
      nextExercise: null,
    }
  },
  computed: {
    settings: function() {
      return find(this.$store.state.panels.configurations, item => {
        return item.panelId === this.panel.id;
      });
    },
  },
  watch: {
    panel: {
      immediate: true,
      handler: 'panelUpdate'
    }
  },
  methods: {
    panelUpdate: function() {
      this.rotation = this.panel.activeRotation !== undefined ? this.panel.activeRotation
        : this.panel.previousRotation

      let order = []
      if (this.rotation !== undefined) {
        order = blockLib.getPanelOrder(this.panel, this.rotation)
      }
      this.order = order
    },
  }
}
</script>

<style scoped>

</style>
